import { createSlice } from '@reduxjs/toolkit'

export const youtubeInfoSlice = createSlice({
  name: 'youtubeInfo',
  initialState: {
    currentPageNum: -1,
    pageNum: 0,
    numPerPage: 1,
    isPageRequesting: false,
    infos: [],
    searchBy: 'handle',
    searchId: '',
  },
  reducers: {
    setNumPerPage: (state, action) => {
        state.numPerPage = action.payload
    },
    setCurrentPageNum: (state, action) => {
        state.currentPageNum = action.payload
    },
    setPageNum: (state, action) => {
        state.pageNum = action.payload
    },
    setIsPageRequesting: (state, action) => {
        state.isPageRequesting = action.payload
    },
    setInfos: (state, action) => {
        state.infos = action.payload
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload
    },
    setSearchId: (state, action) => {
      state.searchId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
    setNumPerPage,
    setCurrentPageNum,
    setPageNum,
    setIsPageRequesting,
    setInfos,
    setSearchBy,
    setSearchId
} = youtubeInfoSlice.actions

export default youtubeInfoSlice.reducer
