import logo from './logo.svg';
import './App.css';
import React from "react";
import axios from 'axios';
import {
  setNumPerPage,
  setInfos,
  setIsPageRequesting,
  setSearchId,
  setSearchBy,
} from "./helpers/redux/reducers/infoReducer";
import { useDispatch, useSelector } from 'react-redux';
import PaginatedItems from './components/pagination/paginatedItems';
import backgroundImage from './assets/images/youtube-logo.jpeg';

function App() {
  // const infoBaseUrl = 'http://localhost:5000/youtube_video_info'
  const infoBaseUrl = 'https://videosplitor.voodoocast.io/youtube_video_info'

  const [error, setError] = React.useState('');

  const dispatch = useDispatch();
  const numPerPage = useSelector(state => state.youtubeInfo.numPerPage);
  const isPageRequesting = useSelector(state => state.youtubeInfo.isPageRequesting);
  const searchId = useSelector(state => state.youtubeInfo.searchId);
  const searchBy = useSelector(state => state.youtubeInfo.searchBy);

  const getByChannelId = async (channelId = '') => {
    let havingNextPage = true;
    let nextPageToken = '';
    let items = [];
    while (havingNextPage) {
      const pageTokenQuery = nextPageToken ? `&pageToken=${nextPageToken}` : '';

      const response = await axios.get(`${infoBaseUrl}/get_video_info/by_channel?channelId=${channelId}&numPerPage=${500}${pageTokenQuery}`);
      
      if (response?.data?.error?.message) {
        setError(response?.data?.error?.message)
      }

      // items = [...items, ...response.data.items];
      items = [...items, ...(response.data.items?.filter(item => item.id?.kind === "youtube#video"))];
      if (response?.data?.nextPageToken) {
        nextPageToken = response?.data?.nextPageToken
      } else {
        havingNextPage = false;
      }
    }
    return items;
  }

  const getByPlaylistId = async (playlistId = '') => {
    let havingNextPage = true;
    let nextPageToken = '';
    let items = [];
    while (havingNextPage) {
      const pageTokenQuery = nextPageToken ? `&pageToken=${nextPageToken}` : '';

      const response = await axios.get(`${infoBaseUrl}/get_video_info/by_playlist?playlistId=${playlistId}&numPerPage=${500}${pageTokenQuery}`);
      
      if (response?.data?.error?.message) {
        setError(response?.data?.error?.message)
      }

      // items = [...items, ...response.data.items];
      items = [...items, ...(response.data.items ?? [])];
      if (response?.data?.nextPageToken) {
        nextPageToken = response?.data?.nextPageToken
      } else {
        havingNextPage = false;
      }
    }
    return items;
  }

  const handleGetInfo = async (e) => {
    if (!searchId) {
      setError(`Youtube ${searchBy} is missing.`)
      return;
    }

    dispatch(setInfos([]));
    dispatch(setIsPageRequesting(true));

    try {
      let items = [];
      switch (searchBy) {
        case "handle":
          const response = await axios.get(`${infoBaseUrl}/get_channels?handle=${searchId}`);
          const channels = response.data?.items ?? [];
          for (let channel of channels) {
            let _items = await getByChannelId(channel?.id?.channelId);
            items = [...items, ..._items]
          }
          break;
        case "channelId":
          items = await getByChannelId(searchId);
          break;
        case "playlistId":
          items = await getByPlaylistId(searchId);
          break;
        default:
          break;
      }
      dispatch(setInfos([...items]))
    } catch (err) {
      setError('Something error');
      setTimeout(() => setError(''), 10000)
    }
    dispatch(setIsPageRequesting(false));
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
      }}
    >
      <div className="container">
        <div className="request-form">
          {error && 
            <div className="error-txt">
              {error}
            </div>
          }
          <div className="request-form-inputs">
            <div className="form-field">
              <label>Search By: </label>
              <select
                value={searchBy}
                onChange={e => dispatch(setSearchBy(e.target.value))}
              >
                <option value="handle">Handle</option>
                <option value="channelId">Channel Id</option>
                <option value="playlistId">Playlist Id</option>
              </select>
            </div>
            <div className="form-field">
              <label htmlFor="searchId">{`${searchBy?.[0]?.toUpperCase()}${searchBy?.slice(1)}`}: </label>
              <input
                id="searchId"
                className="channel-input"
                value={searchId}
                onChange={e => {
                  setError('');
                  dispatch(setSearchId(e.target.value))
                }}
              />
            </div>

            <button
              className="channel-btn"
              onClick={handleGetInfo}
              disabled={isPageRequesting}
            >
              Get Info
            </button>
          </div>
          
        </div>

        <PaginatedItems itemsPerPage={numPerPage} />

      </div>
    </div>
  );
}

export default App;
