import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNumPerPage
} from '../../helpers/redux/reducers/infoReducer';
import csvDownload from 'json-to-csv-export'

const PageNumSelector = () => {
  const dispatch = useDispatch();
  const numPerPage = useSelector(state => state.youtubeInfo.numPerPage)

  return (
    <div className="form-field">
      <label htmlFor="numPerPage">Number of Per Page: </label>
      <select
        id="numPerPage"
        value={numPerPage}
        onChange={e => {
          dispatch(setNumPerPage(e.target.value))
        }}
      >
        <option value={1}>1</option>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
      </select>
    </div>
  )
}

function Items({ currentItems }) {
  const isPageRequesting = useSelector(state => state.youtubeInfo.isPageRequesting);
  const searchBy = useSelector(state => state.youtubeInfo.searchBy);

  return (
    <div className="info-body">
      {isPageRequesting && <div className="loader"></div>}
      {(currentItems ?? []).map((item, idx) => (
        <div key={idx}>
          <div style={{ padding: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <img
                src={`${item?.snippet?.thumbnails?.default?.url}`}
                width={item?.snippet?.thumbnails?.default?.width}
                height={item?.snippet?.thumbnails?.default?.height}
                alt="youtube_thumbnail"
              />
              <a href={`https://www.youtube.com/watch?v=${searchBy !== 'playlistId' ? item?.id.videoId : item?.snippet?.resourceId?.videoId}`}>
                {`https://www.youtube.com/watch?v=${searchBy !== 'playlistId' ? item?.id.videoId : item?.snippet?.resourceId?.videoId}`}
              </a>
            </div>
            <table>
              <tbody>
                <tr>
                  <td className="title">Title: </td>
                  <td>{item?.snippet?.title}</td>
                </tr>
                <tr>
                  <td className="title">Video ID:</td>
                  <td>{searchBy !== 'playlistId' ? item?.id.videoId : item?.snippet?.resourceId?.videoId}</td>
                </tr>
                <tr>
                  <td className="title description">Video Description:</td>
                  <td>{item?.snippet?.description}</td>
                </tr>
                <tr>
                  <td className="title">Published At:</td>
                  <td>{item?.snippet?.publishedAt.replace('T', ' ').replace('Z', '')}</td>
                </tr>
                <tr>
                  <td className="title">Updated At:</td>
                  <td>{(item?.snippet?.updatedAt ?? '').replace('T', ' ').replace('Z', '')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
}

const PaginatedItems = ({ itemsPerPage }) => {
  const infos = useSelector(state => state.youtubeInfo.infos);
  const searchBy = useSelector(state => state.youtubeInfo.searchBy);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  React.useEffect(() => {
    setItemOffset(0)
  }, [itemsPerPage])

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = Math.floor(itemOffset) + Math.floor(itemsPerPage);
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  console.log({infos})
  const currentItems = infos.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(infos.length / itemsPerPage);

  const dataToConvert = {
    data: infos.map(info => {
      return {
        videoId: searchBy !== 'playlistId' ? info?.id.videoId : info?.snippet?.resourceId?.videoId,
        title: info?.snippet?.title,
        description: info?.snippet?.description,
        publishedAt: info?.snippet?.publishedAt,
        updatedAt: info?.snippet?.updatedAt ?? '',
      }
    }),
    filename: 'youtube_playslist',
    delimiter: ',',
    headers: ['videoId', "title", "description", "publishedAt", "updatedAt"]
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = infos?.length 
			? (event.selected * itemsPerPage) % infos.length
			: 0;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="info-section">
      <div className="info-header">
        <PageNumSelector />
      </div>
      <Items currentItems={currentItems} />
      <div className="info-footer">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
        <button 
          style={{
            height: '30px'
          }}
          onClick={() => csvDownload(dataToConvert)}
        >
          Download CSV
        </button>
      </div>
    </div>
  );
}

export default PaginatedItems;
